<template>
  <div class="pager">
    <div class="nav">
      <van-nav-bar :title="$t('loan.applyForaLoan')" left-arrow @click-left="$router.go(-1)" />
    </div>

    <div class="box">
      <div class="financialCycle">
        <div v-if="info" class="cycle_top">
          <div>
            <span>{{ info.days }}{{ $t("financial.list.sufix") }}</span>
            <span>{{ $t("purchase.financialCycle") }}</span>
          </div>
          <div>
            <span>{{ info.day_rate }}%</span>
            <span>{{ $t("loan.interestRate") }}</span>
          </div>
          <div>
            <span>{{ info.min }}~{{ info.max }}</span>
            <span>{{ $t("purchase.limitation") }}</span>
          </div>
        </div>
        <div style="height: 41px;" />
        <div class="investment">
          <div style="width: 100%; display: flex;justify-content: space-between;align-items: center;">
            <span>{{ $t("loan.loanAmount") }}</span>
            <span>
              <span>{{ $t('loan.lixi') }}</span>
              <span>{{ estimate }}</span>
            </span>
          </div>
          <div>
            <input type="text" :placeholder="$t('loan.PleaseEnterTheLoanAmount')" v-model="value"
              oninput="this.value=this.value.replace(/[^\d.]/g,'').replace(/(\.\d+)\.+/g, '$1')" />
            <div>
              <span>{{ $t("fuhao") }}</span>
              <span v-if="false" @click="value = available">{{
                $t("All")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <van-row style="margin-top: 2rem;" class="message">
        {{ $t("gjrz.sfzzm") }}
      </van-row>
      <van-row class="uploading-image">
        <van-uploader :beforeRead="beforeRead" :after-read="aftersfza">
          <img :src="sfza ? sfza : ''" ref="sfza" v-if="sfza" style="max-width:100%;max-height:10rem;text-align:center" />
        </van-uploader>
      </van-row>
      <van-row class="message">{{ $t("gjrz.sfzfm") }}</van-row>
      <van-row class="uploading-image">
        <van-uploader :beforeRead="beforeRead" :after-read="aftersfzb">
          <img :src="sfzb ? sfzb : ''" ref="sfzb" v-if="sfzb" style="max-width:100%;max-height:10rem;text-align:center" />
        </van-uploader>
      </van-row>
      <van-row class="message">{{ $t("loan.holdingPassport") }}</van-row>
      <van-row class="uploading-image">
        <van-uploader :beforeRead="beforeRead" :after-read="aftersfzc">
          <img :src="sfzc ? sfzc : ''" ref="sfzc" v-if="sfzc" style="max-width:100%;max-height:10rem;text-align:center" />
        </van-uploader>
      </van-row>




    </div>

    <div class="agreement">
      <van-checkbox v-model="checked">
        {{ $t('Please_read_and_agree') }}
        <span style="color:#f0b82d" @click="agreement">
          {{ $t('loan_agreement') }}
        </span>
      </van-checkbox>
    </div>

    <van-button type="primary" style="margin-top: 95px;" @click="onSubscribe">
      {{ $t("purchase.subscribe") }}
    </van-button>

    <van-popup :close-on-click-overlay="false" v-model="showDialog"
      style="background-color:transparent; overflow:hidden;">
      <div class="loadingWrap">
        <van-loading v-if="showDialog" type="spinner" />
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: undefined,
      estimate: 0,
      available: "0",
      value: "", //贷款金额
      showDialog: false,
      sfza: "",
      sfzb: "",
      sfzc: "",
      checked: false
    };
  },
  watch: {
    value: {
      handler(val) {
        const value = val * (this.info.day_rate / 100) * this.info.days;
        this.estimate = value == 0 ? value : value.toFixed(2);
      },
    },
  },
  created() {
    this.info = JSON.parse(this.$route.params.id);
    this.onLoadData();
  },
  methods: {
    onLoadData() { },
    beforeRead(file) {
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/gif"
      ) {
        this.$toast(this.$t("gjrz.toast"));
        return false;
      }
      return true;
    },
    // 服务条款
    agreement() {
      this.$router.push({ path: "/person/loanagreement" });
    },
    async aftersfza(file) {
      const param = new FormData();
      param.append("file", file.file);
      const { data } = await this.$http.post("/home/user/uploadimg", param);
      if (data) {
        this.sfza = data.data.url;
      }
    },
    async aftersfzb(file) {
      const param = new FormData();
      param.append("file", file.file);
      const { data } = await this.$http.post("/home/user/uploadimg", param);
      if (data) {
        this.sfzb = data.data.url;
      }
    },
    async aftersfzc(file) {
      const param = new FormData();
      param.append("file", file.file);
      const { data } = await this.$http.post("/home/user/uploadimg", param);
      if (data) {
        this.sfzc = data.data.url;
      }
    },
    onSubscribe() {
      if (this.value == "") {
        return this.$toast(this.$t("loan.PleaseEnterTheLoanAmount"));
      }
      if (!this.sfza || !this.sfzb) {
        this.$toast(this.$t('gjrz.sczj'))
        return;
      }
      if (!this.sfzc) {
        this.$toast(this.$t('loan.holdingPassport'))
        return;
      }
      if (!this.checked) {
        this.$toast(this.$t('Please_read_and_agree') + " " + this.$t('loan_agreement'))
        return;
      }
      this.showDialog = true;
      this.$http
        .post("/home/user/loan", {
          id: this.info.id,
          money: this.value,
          img_z: this.sfza,
          img_f: this.sfzb,
          img_s: this.sfzc
        })
        .then((res) => {
          this.showDialog = false;
          if (res.data.code == 200) {
            this.$toast(this.$t("common.actionok"));
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          } else {
            this.$toast(this.$t(res.data.msg));
          }
        })
        .catch((err) => {
          this.$toast(this.$t("common.actionok"));
          this.showDialog = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
.pager {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #e3e6ea;
  overflow-y: scroll;

  /deep/.van-button--primary {
    border-radius: 5px;
    background: #f0b82d;
    border: none;
    position: fixed;
    bottom: 32px;
    left: 32px;
    right: 32px;
  }

  .agreement {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 32px;
    bottom: 90px;

    // /deep/.van-icon {
    //   height: 1rem;
    //   width: 1rem;
    //   font-size: 0.66667rem;
    //   color: #fff !important;
    // }

    /deep/.van-checkbox__label {
      margin-left: 3px;
      margin-right: 3px;
    }

    .van-checkbox__icon--checked .van-icon {
      color: #fff !important;
      background-color: #f0b82d;
      border-color: #f0b82d;
      border-radius: 4px;
      font-size: 0.66667rem;
    }
  }
}

.nav {
  position: fixed;
  width: 100vw;
  font-size: 1.12rem;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.box {
  padding: 46px 16px 0px 16px;
  width: 100vw;
  padding-bottom: 110px;

  .top {
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 39px;
      height: 39px;
      margin-right: 11px;
    }

    div {
      display: flex;
      flex-direction: column;
      color: #333;

      span:first-child {
        font-size: 13px;
        font-weight: 400;
      }

      span:last-child {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .financialCycle {
    margin-top: 26px;
    position: relative;

    .cycle_top {
      top: 0px;
      left: 14px;
      width: calc(100% - 28px);
      position: absolute;
      background-color: #fff;
      padding: 14px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      div {
        display: flex;
        flex-direction: column;
        color: #333;

        span:first-child {
          font-size: 16px;
          font-weight: 500;
        }

        span:last-child {
          margin-top: 6px;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .cycle_bottom {
      padding: 54px 0 19px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      background: rgba(255, 255, 255, 0.55);

      div {
        display: flex;
        flex-direction: column;

        span:first-child {
          color: #777;
          font-size: 14px;
          font-weight: 400;
        }

        span:last-child {
          color: #363636;
          margin-top: 15px;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  .income {
    margin-top: 10px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: rgba(255, 255, 255, 0.55);

    div {
      display: flex;
      flex-direction: column;

      span:first-child {
        color: #777;
        font-size: 14px;
        font-weight: 400;
      }

      span:last-child {
        color: #363636;
        margin-top: 15px;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .investment {
    margin-top: 10px;
    padding: 16px;
    padding-top: 50px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.55);

    div:first-child {
      color: #333;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 9px;
    }

    div:last-child {
      width: 100%;
      height: 38px;
      background: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 8px;

      input {
        flex: 2;
        border: none;
        color: #333;
        font-size: 15px;
        font-weight: 400;
      }

      div {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        span:first-child {
          color: #777;
          font-size: 12px;
          font-weight: 400;
        }

        span:last-child {
          color: #363636;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }

  .tips {
    margin-top: 10px;
    padding: 16px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.55);

    span:first-child {
      color: #333;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 9px;
    }

    span:last-child {
      color: #333;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
    }
  }

  .itemBg {
    margin-top: 10px;
    padding: 16px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.55);
  }

  .uploading-image {
    height: 10rem;
    width: 80%;
    margin: 2% 10% 5% 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.02667rem dashed grey;

    .van-uploader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      /deep/.van-uploader__wrapper {
        width: 100%;
        height: 100%;
        justify-content: center;
      }

      /deep/.van-uploader__upload {
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: #fff;
        color: #fff;
      }
    }
  }

  
}
</style>
